.markdown {
    color: $body-color;
    font-size: 13px;
    line-height: 2;
  }
  
  .highlight {
    line-height: 1.5;
  }
  
  .markdown img {
    max-width: calc(100% - 32px);
  }
  
  .markdown p > img {
    margin: 34px 0;
    box-shadow: 0 8px 20px rgba(143, 168, 191, 0.35);
  }
  
  .markdown p > img.markdown-inline-image {
    margin: 0;
    box-shadow: none;
  }
  
  .markdown h1 {
    margin-top: 8px;
    margin-bottom: 20px;
    color: $gray-dark;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
  
    .subtitle {
      margin-left: 12px;
  
      .ant-row-rtl & {
        margin-right: 12px;
        margin-left: 0;
      }
    }
  }
  
  .markdown h2 {
    font-size: 24px;
    line-height: 32px;
  }
  
  .markdown h2,
  .markdown h3,
  .markdown h4,
  .markdown h5,
  .markdown h6 {
    clear: both;
    margin: 1.6em 0 0.6em;
    color: $gray-dark;
    font-weight: 500;
  }
  
  .markdown h3 {
    font-size: 18px;
  }
  .markdown h4 {
    font-size: 16px;
  }
  .markdown h5 {
    font-size: 13px;
  }
  .markdown h6 {
    font-size: 12px;
  }
  
  .markdown hr {
    clear: both;
    height: 1px;
    margin: 56px 0;
    background: $border-color;
    border: 0;
  }
  
  .markdown p,
  .markdown pre {
    margin: 1em 0;
  }
  
  .markdown ul > li {
    margin-left: 20px;
    padding-left: 4px;
    list-style-type: circle;
  
    .ant-row-rtl & {
      margin-right: 20px;
      margin-left: 0;
      padding-right: 4px;
      padding-left: 0;
    }
    &:empty {
      display: none;
    }
  }
  
  .markdown ol > li {
    margin-left: 20px;
    padding-left: 4px;
    list-style-type: decimal;
  
    .ant-row-rtl & {
      margin-right: 20px;
      margin-left: 0;
      padding-right: 4px;
      padding-left: 0;
    }
  }
  
  .markdown ul > li > p,
  .markdown ol > li > p {
    margin: 0.2em 0;
  }
  
  .markdown code {
    margin: 0 1px;
    padding: 0.2em 0.4em;
    font-size: 0.9em;
    background: #f5f5f5;
    border: 1px solid $border-color;
    border-radius: 3px;
  }
  
  .markdown pre {
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
    background: #f5f5f5;
    border-radius: $border-radius;
  }
  
  .markdown pre code {
    margin: 0;
    padding: 0;
    overflow: auto;
    color: $body-color;
    font-size: $font-size-base;
    background: #f5f5f5;
    border: none;
  }
  
  .markdown strong,
  .markdown b {
    font-weight: 500;
  }
  
  .markdown > table {
    width: 100%;
    margin: 8px 0 16px;
    empty-cells: show;
    border: 1px solid $border-color;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .markdown > table th {
    color: #5c6b77;
    font-weight: 500;
    white-space: nowrap;
    background: rgba(0, 0, 0, 0.02);
  }
  
  .markdown > table th,
  .markdown > table td {
    padding: 16px 24px;
    text-align: left;
    border: 1px solid $border-color;
  }
  
  .markdown blockquote {
    margin: 1em 0;
    padding-left: 0.8em;
    color: $body-color;
    font-size: 90%;
    border-left: 4px solid $border-color;
  
    .ant-row-rtl & {
      padding-right: 0.8em;
      padding-left: 0;
      border-right: 4px solid $border-color;
      border-left: none;
    }
  }
  
  .markdown blockquote p {
    margin: 0;
  }
  
  .markdown .anchor {
    margin-left: 8px;
    opacity: 0;
    transition: opacity .3s;
  
    .ant-row-rtl & {
      margin-right: 8px;
      margin-left: 0;
    }
  }
  
  .markdown .waiting {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .markdown a.edit-button {
    display: inline-block;
    margin-left: 8px;
    text-decoration: none;
  
    .ant-row-rtl & {
      margin-right: 8px;
      margin-left: 0;
    }
    i {
      color: $body-color;
  
      &:hover {
        color: $brand-primary;
      }
    }
  
    .anticon {
      display: block;
      font-size: 16px;
    }
  }
  
  .markdown h1:hover .anchor,
  .markdown h2:hover .anchor,
  .markdown h3:hover .anchor,
  .markdown h4:hover .anchor,
  .markdown h5:hover .anchor,
  .markdown h6:hover .anchor {
    display: inline-block;
    opacity: 1;
  }
  
  .markdown > br,
  .markdown > p > br {
    clear: both;
  }
  
  .markdown.api-container {
    overflow-x: auto;
  
    table {
      min-width: 720px;
      margin: 2em 0;
      font-size: $font-size-base ;
      font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace;
      line-height: $line-height-base;
      border: 1px solid $border-color;
  
      th,
      td {
        padding: 12px;
        border-color: $border-color;
        border-width: 1px 0;
      }
  
      th {
        padding-top: 14px;
        border-width: 0 0 2px 0;
      }
  
      tbody tr {
        transition: all 0.3s;
        &:hover {
          background: rgba(60, 90, 100, 0.04);
        }
      }
  
      td {
        &:first-child {
          width: 20%;
          color: $gray;
          font-weight: 600;
        }
        &:nth-child(3) {
          width: 22%;
          color: $magenta;
          font-size: $font-size-base ;
          word-break: break-all;
        }
        &:nth-child(4) {
          width: 16%;
          font-size: $font-size-base ;
        }
      }
    }
  
    hr {
      margin: 12px 0;
    }
  }
  
  .grid-demo,
  [id^='components-grid-demo-'] {
    .demo-row,
    .code-box-demo .demo-row {
      margin-bottom: 8px;
      overflow: hidden;
      background-image: linear-gradient(
        90deg,
        #f5f5f5 4.16666667%,
        transparent 4.16666667%,
        transparent 8.33333333%,
        #f5f5f5 8.33333333%,
        #f5f5f5 12.5%,
        transparent 12.5%,
        transparent 16.66666667%,
        #f5f5f5 16.66666667%,
        #f5f5f5 20.83333333%,
        transparent 20.83333333%,
        transparent 25%,
        #f5f5f5 25%,
        #f5f5f5 29.16666667%,
        transparent 29.16666667%,
        transparent 33.33333333%,
        #f5f5f5 33.33333333%,
        #f5f5f5 37.5%,
        transparent 37.5%,
        transparent 41.66666667%,
        #f5f5f5 41.66666667%,
        #f5f5f5 45.83333333%,
        transparent 45.83333333%,
        transparent 50%,
        #f5f5f5 50%,
        #f5f5f5 54.16666667%,
        transparent 54.16666667%,
        transparent 58.33333333%,
        #f5f5f5 58.33333333%,
        #f5f5f5 62.5%,
        transparent 62.5%,
        transparent 66.66666667%,
        #f5f5f5 66.66666667%,
        #f5f5f5 70.83333333%,
        transparent 70.83333333%,
        transparent 75%,
        #f5f5f5 75%,
        #f5f5f5 79.16666667%,
        transparent 79.16666667%,
        transparent 83.33333333%,
        #f5f5f5 83.33333333%,
        #f5f5f5 87.5%,
        transparent 87.5%,
        transparent 91.66666667%,
        #f5f5f5 91.66666667%,
        #f5f5f5 95.83333333%,
        transparent 95.83333333%
      );
    }
    .ant-row > div,
    .code-box-demo .ant-row > div {
      min-height: 30px;
      margin-top: 8px;
      margin-bottom: 8px;
      color: #fff;
      text-align: center;
      border-radius: 0;
    }
    .code-box-demo .ant-row > div:not(.gutter-row) {
      padding: 16px 0;
      background: $brand-primary;
      &:nth-child(2n + 1) {
        background: rgba($brand-primary, 0.75);
      }
      p {
        color:#fff
      }
    }
    .ant-row .demo-col,
    .code-box-demo .ant-row .demo-col {
      margin-top: 0;
      margin-bottom: 0;
      padding: 30px 0;
      color: $white;
      font-size: 18px;
      text-align: center;
      border: none;
    }
    .ant-row .demo-col-1 {
      background: rgba($brand-primary, 0.75);
    }
    .ant-row .demo-col-2,
    .code-box-demo .ant-row .demo-col-2 {
      background: rgba($brand-primary, 0.5);
    }
    .ant-row .demo-col-3,
    .code-box-demo .ant-row .demo-col-3 {
      color: #999;
      background: rgba(255, 255, 255, 0.2);
    }
    .ant-row .demo-col-4,
    .code-box-demo .ant-row .demo-col-4 {
      background: rgba($brand-primary, 0.6);
    }
    .ant-row .demo-col-5,
    .code-box-demo .ant-row .demo-col-5 {
      color: #999;
      background: rgba(255, 255, 255, 0.2);
    }
    .code-box-demo .height-100 {
      height: 100px;
      line-height: 100px;
    }
    .code-box-demo .height-50 {
      height: 50px;
      line-height: 50px;
    }
    .code-box-demo .height-120 {
      height: 120px;
      line-height: 120px;
    }
    .code-box-demo .height-80 {
      height: 80px;
      line-height: 80px;
    }
  }
  
  [id='components-grid-demo-playground'],
  [id='components-grid-demo-gutter'] {
    > .code-box-demo .ant-row > div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  
  // For Changelog
  .markdown ul.ant-timeline {
    line-height: 2;
    li.ant-timeline-item {
      margin: 0;
      padding: 0 0 30px;
      list-style: none;
      .ant-timeline-item-content {
        position: relative;
        top: -14px;
        padding-left: 32px;
        font-size: 13px;
        > h2 {
          margin-top: 0;
          padding-top: 4px;
        }
      }
    }
    li.ant-timeline-item:first-child {
      margin-top: 40px;
    }
  }
  