
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;

nz-form-label label::after {
    content: '' !important;
}

html {
    font-size: 92%;
}

body {
    background: #f5f7f9 !important;
    letter-spacing: 0.4px;
}

.series-upload .ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
}

.ant-checkbox-inner:after {
    left: 22% !important;
    top: 50% !important;
}

.ant-checkbox-inner {
    width: 16px !important;
    height: 16px !important;
}

.ant-modal-body {
    padding: 24px !important;
    font-size: 13px !important;
    line-height: 1.5715 !important;
    word-wrap: break-word !important;
  }

button.ant-btn.ant-btn-primary::after {
    content: '';
    display: none;
}

// .ant-tooltip {
//     font-size: 13px !important;
//     line-height: initial !important;
//     max-width: 300px !important;
// }

// .ant-tooltip-inner {
//     max-width: 300px !important; 
// }

// .ant-select {
//     color: inherit !important; 
//     font-size: 13px !important; 
//     line-height: 1.5 !important; 
//     letter-spacing: 0.6px !important; 
//     font-weight: 300 !important; 
// }

// .ant-select {
//     font-size: 13px !important;
// }

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
//     height: 40.6px !important;
// }

// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
//     border-radius: 4px !important;
//     height: 42.6px !important;
// }

// .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
//     line-height: 40.6px !important;
// }

// .ant-input {
//     padding: 10px 16px !important;
//     border-radius: 4px !important;
// }

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
    color: #fff !important;
    background-color: #e7e7e700 !important;
}


.logos-slider .swiper-wrapper {
    display: flex !important;
    align-items: center !important;
}
 
.lgx-banner-inner {
    max-height: 130px;
}

.swiper-slide {
    height: 125px;
} 

.header-button::after {
    display: none;
}

button.button.button--white.button--block {
    overflow: hidden;
    color: white;
}

button.ant-btn {
    font-size: 12px;
    letter-spacing: 0.4px;
}

button.ant-btn::after {
    content: '';
    display: none;
}

button.ant-pagination-item-link::after {
    content: '';
    display: none;
}

button.ant-drawer-close::after {
    display: none !important;
    box-shadow: none !important;
}

button.ant-drawer-close {
    box-shadow: none !important;
}


::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
  
  
::-webkit-scrollbar-track {
    background: #ffffff00;
}


::-webkit-scrollbar-thumb {
    background: rgb(175, 175, 175); 
    border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
    background: rgb(134, 134, 134); 
}

.ant-checkbox-inner {
    border: 1px solid #c0c0c0 !important;
}

app-customfooter {
    z-index: 999;
    position: relative;
}





.mcard-yellow {
    background: #FEF8E6;
    border-radius: 10px;
    padding: 25px 15px;
    cursor: pointer;

    .mcard-header-section {
        display: flex;
        justify-content: space-between;
    }
    
    .mcard-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.06em;
        color: #8B7F55;
    }
    
    .mcard-tag {
        background: #F3E4A9;
        border-radius: 6px;
        line-height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 14px;
        font-weight: 600;
        letter-spacing: 0.6px;
        color: #8B7F55;
    }
    
    .mcard-sub-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.6px;
        color: #B79E46;
    }
    
    .mcard-sub-title-2 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.06em;
        color: #8B7F55;
    }
    
    .mcard-sub-title {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.mcard-green {
    background:#DCF7E9;
    border-radius: 10px;
    padding: 25px 15px;
    cursor: pointer;

    .mcard-header-section {
        display: flex;
        justify-content: space-between;
    }
    
    .mcard-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.06em;
        color: #5E947B;
    }
    
    .mcard-tag {
        background: #A7F4CC;
        border-radius: 6px;
        line-height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 14px;
        font-weight: 600;
        letter-spacing: 0.6px;
        color: #5E947B;
    }
    
    .mcard-sub-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.6px;
        color: #53C691;
    }
    
    .mcard-sub-title-2 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.06em;
        color: #5E947B;
    }
    
    .mcard-sub-title {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.mcard-red {
    background:#FFE1E2;
    border-radius: 10px;
    padding: 25px 15px;
    cursor: pointer;

    .mcard-header-section {
        display: flex;
        justify-content: space-between;
    }
    
    .mcard-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.06em;
        color: #9D6D6D;
    }
    
    .mcard-tag {
        background: #FEB6B6;
        border-radius: 6px;
        line-height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 14px;
        font-weight: 600;
        letter-spacing: 0.6px;
        color: #9D6D6D;
    }
    
    .mcard-sub-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.6px;
        color: #FF7676;
    }
    
    .mcard-sub-title-2 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.06em;
        color: #9D6D6D;
    }
    
    .mcard-sub-title {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.mcard-blue {
    background:rgba(216, 244, 255, 0.69);
    border-radius: 10px;
    padding: 25px 15px;
    cursor: pointer;

    .mcard-header-section {
        display: flex;
        justify-content: space-between;
    }
    
    .mcard-title {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.06em;
        color: #0087BA;
    }
    
    .mcard-tag {
        background: #C0EDFF;
        border-radius: 6px;
        line-height: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 14px;
        font-weight: 600;
        letter-spacing: 0.6px;
        color: #0087BA;
    }
    
    .mcard-sub-title {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        letter-spacing: 0.6px;
        color: #00A1DE;
    }
    
    .mcard-sub-title-2 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.06em;
        color: #015A7B;
    }
    
    .mcard-sub-title {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.simple-card {
    background: #FFFFFF;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
}

.simple-card {
    padding: 8px 14px 5px;
    min-width: 150px;

    .simple-card-heading {
        font-size: 12px;
        color: #343434;
        letter-spacing: 0.4px;
    }
    
    .simple-card-content {
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        letter-spacing: 0.2px;
    }
}

.Rectangle6Stack-uq5thy-37.gmrHpm {
    position: relative;
}

.Rectangle6-uq5thy-25.hcBqMg {
    top: 20px;
    left: 29px;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    right: 23px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 5px 50px 1px #0000001a;
}

.Rectangle5-uq5thy-26.kSUVJX {
    top: 10px;
    left: 13px;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    right: 11px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 5px 5px 50px 1px #0000000d;
}




.Rectangle4-uq5thy-27.eOIWbU {
    top: 0px;
    left: 0px;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    z-index: 999;
    right: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-shadow: 0px 0px 10px 1px #0000000d;
    width: calc(100vw - 405px);
}

.LessonTitleColumn-uq5thy-30.iDhDvq {
    width: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 19px;
    margin-left: 19px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 19px;
}

span.LessonTitle-uq5thy-28.cyeREg {
    font-family: Poppins;
    background-color: transparent;
    color: rgba(46, 59, 74, 1);
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    -webkit-letter-spacing: 1.2px;
    -moz-letter-spacing: 1.2px;
    -ms-letter-spacing: 1.2px;
    letter-spacing: 1.2px;
}

.LessonDesc-uq5thy-29.jZEFfF {
    font-family: Poppins;
    width: auto;
    background-color: transparent;
    color: rgba(128, 128, 128, 1);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    -webkit-letter-spacing: 1.2px;
    -moz-letter-spacing: 1.2px;
    -ms-letter-spacing: 1.2px;
    letter-spacing: 1.2px;
    margin-top: 15px;
    margin-right: 15px;
    -webkit-line-clamp: 4;
    -moz-line-clamp: 4;
    -ms-line-clamp: 4;
    -o-line-clamp: 4;
    line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -ms-box-orient: vertical;
    -o-box-orient: vertical;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: flex;
    padding-top: 10px;
}

.LessonCard-uq5thy-24.cEvBJy {
    height: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    margin-left: 41px;
}

.ant-drawer-content {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.Group9-uq5thy-16.iefGbQ {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 56px;
    margin-left: 0px;
    margin-right: 31px;
}

.Group7-uq5thy-17.kLOjxr {
    width: 25px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.Rectangle8Stack-uq5thy-21.dQNtdi {
    width: 26px;
    height: 100%;
    position: relative;
}

.Rectangle3-uq5thy-19.eTRrXl {
    top: 0px;
    left: 0px;
    width: 26px;
    height: 25px;
    background-color: rgba(255,255,255,1);
    position: absolute;
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    border-bottom-left-radius: 13px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    box-shadow: 0px 3px 10px 1px #0000001a;
}

span.Style1-uq5thy-20.iJlwng {
    font-family: Poppins;
    width: 4px;
    height: 16px;
    background-color: transparent;
    color: rgba(16,175,225,1);
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    margin-top: 3px;
    margin-left: 10px;
}

.Rectangle8-uq5thy-18.jMKShj {
    top: 0px;
    left: 12px;
    width: 1px;
    height: calc(100% + 60px);
    background-color: rgba(128,128,128,0.2);
    position: absolute;
}

button.ant-btn.secondary-btn {
    border: none;
    box-shadow: none;
    color: #436dfd;
    border: 1.5px solid #436dfd;
    border-radius: 6px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    padding: 0.65rem 3.5rem
}

.ant-modal-body .ant-table {
    box-shadow: none !important;
}

button.ant-btn.ant-btn-primary {
    // border: none;
    // box-shadow: none;
    // background: linear-gradient(180deg, #1789cc, #0f67c7);
    // border-radius: 6px;
    // color: white;
    // font-size: 12px;
    // letter-spacing: 0.8px;
    // padding: 0.65rem 2.5rem;
    // text-transform: uppercase;
    // box-shadow: 0px 3px 5px 2px #106ec842;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    background-color: #0099a8 !important;
    border-color: #0099a8 !important;
}

button.ant-btn.ant-btn-primary:hover {
    box-shadow: none !important;
}

.ant-modal-footer {
    display: none;
}

.modal-js-confirm .ant-modal-footer {
    display: block;
}

button.ant-btn-dangerous.ant-btn-primary {
    background-color: #004A7F;
    border-color: #004A7F;
    color: #fff;
}

button.ant-btn-dangerous {
    padding: 0.65rem 3.5rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
}

.lesson-completion-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.lesson-completion-heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.6px;
    color: #363636;
}

.progress-percent{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.04em;
    color: #000000;
}

.progress-bar {
    display: flex;
    flex-direction: row;
}

.completion-bar{
    width: 130px;
    height: 5px;
    background: #52BAE4;
    border-radius: 10px;
    cursor: pointer;
}

.incomplete-bar {
    width: 130px;
    height: 5px;
    background: rgba(196, 196, 196, 0.2);
    border-radius: 10px;
    margin-left: 5px;
    cursor: pointer;
}

.next-prev-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
}

.prev-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    cursor: pointer;
}

.prev-icon-holder { 
    margin-right: 10px;
    position: relative;
    top: -1px;
}

.prev-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #9E9E9E;
}

.next-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; 
    cursor: pointer;
}

.next-icon-holder {
    margin-left: 10px;
    position: relative;
    top: -1px;
}

.next-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #9E9E9E;
}

.single-quiz-option {
    display: flex;
    flex-direction: row;
    background-color: #F5F7F9;
    height:50px;
    width:550px;
    margin-bottom:8px;
    justify-content: flex-start;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 6px;

    .ant-radio-wrapper {
        position: relative;
        top: -3px;
    } 

    .ant-radio-inner {
        background: white;
    }
}

.quiz-option-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #202020;
    margin-left: 10px;
    position:relative;
}


.mastered-lessons-container {
    background: white;
    border-radius:8px;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.06);
    padding: 20px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
}

.lesson-individual {
    width:100%;
}

.lesson-name-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.lesson-name-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.lesson-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #5C5C5C;
}

.change-btn {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #436DFD;
    margin-left: 10px;
}

.main-container {
    min-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
}

.input-group {
    margin-bottom: 15px;
}

.input-label {
    margin-bottom: 8px;
    color: #231f20;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: 0.4px;
    font-family: 'Poppins';
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: #0000000f;
    transform: translateY(-50%);
    transition: background-color .3s;
    content: "";
}

.tablecta {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
}

input.quiz-option-text.ant-input {
    background: #f5f7f9;
    border: none;
}

.ant-select-item-option-content {
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 12px;
}

iframe#\:0\.container {
    display: none;
}

body {
    top: 0px !important;
}

.tiles-container {
    text-align: center;
}

div#goog-gt-tt {
    border-radius: 8px !important;
    box-shadow: 0px 4px 20px 5px rgb(0 0 0 / 6%) !important;
    font-family: 'Poppins' !important;
    letter-spacing: 0.6px !important;
    padding: 0px 10px !important;
    text-transform: uppercase;
    font-size: 12px !important;
    
    .logo {
        display: none !important;
    }
    
    
    .activity-links {
        display: none !important;
    }

    .original-text, .gt-hl-layer {
        letter-spacing: 1px !important;
        color: #323232 !important;
    }

    h1.title.gray {
        font-weight: 600 !important;
        font-family: 'Poppins' !important;
        letter-spacing: 1px !important;
    }
    
    .top {
        margin-top: -15px !important;
    }
    
    .middle {
        margin-bottom: -5px !important;
    }
}

.prt50 {
    position: relative;
    top: 50px;
}

.avatar-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;

    .avatar-single {
        width: 120px;
        height: auto;
        margin-bottom: 18px;
        margin-right: 18px;
        cursor: pointer;
        border-radius: 50%;
    }
}

.LessonDesc-uq5thy-29.jZEFfF {
    flex-wrap: wrap;
}


.apexcharts-menu-item {
    min-width: 110px;
}

.ant-select-selector {
    border: 1px solid #edf2f9 !important;
    border-radius: 0.25rem !important;
    color: #53535f !important;
    height: auto !important;
    min-height: 43px !important;
}

.ant-checkbox {
    top: 3px !important;
}

@media (max-width: 992px) {

    ::-webkit-scrollbar {
        width: 0px !important;
        height: 8px !important;
    }

    app-student-left-sidebar, app-parent-left-sidebar, app-admin-left-sidebar, app-teacher-left-sidebar {
        transition: all ease-in 200ms;
        position: fixed;
        z-index: 9999 !important;
        left: -260px;
        height: 100vh;
        overflow: auto;
        background: #f5f7f9;
    }

    app-student-left-sidebar .main-container, app-parent-left-sidebar .main-container, app-admin-left-sidebar .main-container, app-teacher-left-sidebar .main-container{
        padding-bottom: 75px !important;
    }

    app-student-right-sidebar, app-parent-right-sidebar, app-admin-right-sidebar, app-teacher-right-sidebar  {
        transition: all ease-in 200ms;
        position: fixed;
        z-index: 9999 !important;
        right: -260px;
        height: 100vh;
        overflow: auto;
        background: #f5f7f9;
    }

    app-student-right-sidebar .main-container, app-parent-right-sidebar .main-container, app-admin-right-sidebar .main-container, app-teacher-right-sidebar .main-container{
        padding-bottom: 75px !important;
    }

    #background-overlay-for-mobile {
        background: rgba(0, 0, 0, 0.377);
        z-index: 9998;
        display: none;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        display: none;
    }

    .dashboard-container {
        width: 100vw !important;
    }

    .Rectangle4-uq5thy-27.eOIWbU {
        width: calc(100vw - 120px) !important;
    }

    .chat.chat-app .chat-content {
        position: relative !important;
        left: auto !important;
    }

    .ant-drawer-content-wrapper {
        max-width: 95vw;
    }
    nz-radio-group.ant-radio-group {
        width: 100%;
    }
    .lesson-article-container img {
        width: 100%;
    }
    .audioObjImageSvg {
        width: 100%;
    }

    input.ant-input {
        max-width: 100% !important;
    }

    button.ant-btn.ant-btn-primary {
        padding: 0.65rem 2rem !important;
    }
    
    .ant-btn {
        height: auto !important;
    }
    
    button.ant-btn-dangerous {
        padding: 0.65rem 2rem !important;
    }

    .signup-details-container {
        padding-right: 25px !important;
        padding-left: 25px !important;
    }
    .chat.chat-app .chat-content .conversation-footer {
        bottom: 0px !important;
    }

    .search-bar {
        margin-right: 10px;
    }

    .edit-module-btn {
        margin-right: 10px;
    }

    .edit-module-modal-delete-btn {
        margin-top: 10px;
    }
    .board-parent-container {
        flex-direction: column !important;
        .board-dropdown {
            margin-bottom: 15px !important;
        }
    }

    .pending-approval-btn button {
        padding: 0.65rem 1rem !important;
    }

    .save-add-options-add-explanation-btns-container-desktop {
        display: none !important;
    }

    .approve-reject-container {
        left:0 !important;
        width: 100vw !important;
    }
    .points-earning-analytics {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        flex-direction: column !important;
        overflow: auto;
    }
    nz-year-picker {
        min-width: 220px !important;
    }
    
}


@media (min-width: 992px) {
   app-student-mobile-header, app-teacher-mobile-header, app-parent-mobile-header, app-admin-mobile-header {
       display: none !important;
   }

   .add-explanation-btn-container-mobile {
       display: none !important;
   }

   .save-add-option-btns-container-mobile {
       display: none !important;
   }
}

.stats__table {
    box-shadow: none !important;
    max-width: 100%;
    overflow: auto;
}

.ant-table-fixed-header .ant-table-thead > tr > th {
    background: initial !important;
}

.breadcrumbs-text {
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-family: 'Poppins' !important;
    letter-spacing: 0.8px !important;
}

input.ant-input {
    min-height: 43px;
}

nz-input-group input.ant-input {
    min-height: auto;
}

.ant-picker {
    height: 2.92rem !important;
}

.sort-by-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ant-alert {
    padding: 0.5rem 0.5rem 0.5rem 0.8rem !important;
}

i.anticon.ant-table-column-sorter-down.anticon-caret-down {
    color: #E4E4E4;
}

i.anticon.ant-table-column-sorter-up.anticon-caret-up {
    color: #E4E4E4;
}

i.anticon.ant-table-column-sorter-up.anticon-caret-up.active {
    color: #3475d9;
}

i.anticon.ant-table-column-sorter-down.anticon-caret-down.active {
    color: #3475d9;
}

.all-jobs-calendar {
    .ant-picker-calendar .ant-picker-panel .ant-picker-body {
        padding: 0.625rem 0;
        background: #18375f;
        color: white !important;
    }
    
    .ant-picker-cell .ant-picker-cell-inner {
        color: white;
    }
    
    .ant-picker-calendar-mini .ant-picker-content th {
        color: #617fa7;
    }
    
    .ant-picker-cell .ant-picker-cell-inner:hover {
        color: #3f87f5;
    }
    
    .ant-picker-calendar-mini .ant-picker-calendar-header {
        display: none;
    }
    
    
    .ant-picker-calendar.ant-picker-calendar-mini .ant-picker-panel {
        border-top: none;
    }
}

.breadcrumbs div {
    margin-right: 6px !important;
}

.breadcrumbs svg {
    margin-right: 0px !important;
    height: 7px;
}

.download-text {
    color: #1e5799 !important;
    font-size: 11px  !important;
    text-transform: uppercase  !important;
    letter-spacing: 0.8px  !important;
    font-weight: 600  !important;
}

.icon-download div:first-child {
    height: 18px;
    margin-right: 0px !important;
}

nav.nav__ {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06) !important;
    border-top: 1.4px solid #dcdcdc57;
}

.primary-header {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06) !important;
    border-bottom: none !important;
}

.item-detail-navbar {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06) !important;
    border-top: 1.4px solid #dcdcdc57 !important;
    padding-top: 15px !important;
    padding-bottom: 20px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

button.ant-btn.active {
    background: #3f87f5 !important;
    color: white !important;
}

.cal-month-view .cal-open-day-events {
    color: #18375f !important;
    background-color: #f5f7f9 !important;
    box-shadow: none !important;
    border-bottom: 1px solid #ededed !important;
}

.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: #3f87f5 !important;
}

.cal-month-view .cal-day-badge {
    background-color: #3f87f5 !important;
}

.cal-month-view .cal-day-cell.cal-today {
    background-color: #e7effd !important
}

.cal-week-view .cal-event {
    color: #5e5e5e;
}

.cal-week-view .cal-time-events .cal-event {
    border-width: 3px;
    border-radius: 4px;
}

.cal-week-view .cal-current-time-marker {
    display: none;
}

.cal-week-view .cal-header.cal-today {
    background-color: #e8fde7 !important;
}

.cal-week-view .cal-header.cal-today {
    background: #e7effd !important;
}

.cal-week-view .cal-header.cal-weekend span {
    color: #3f87f5 !important;
}

.ant-table-ping-right .ant-table-cell-fix-right-first::after, .ant-table-ping-right .ant-table-cell-fix-right-last::after, .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: inset -10px 0 8px -8px #00000014 !important;
}

.ant-table-ping-left .ant-table-cell-fix-left-first::after, .ant-table-ping-left .ant-table-cell-fix-left-last::after, .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
    box-shadow: inset 10px 0 8px -8px #00000014 !important;
}

.ant-menu-inline-collapsed li a span {
    display: none;
}

.is-side-nav-dark .side-nav {
    background-color: #ffffff !important;
}

.common-layout.is-default.is-folded .header .logo__ {
    width: 50px;
    position: relative;
    top: 8px;
    left: -3px;
}

ul.ant-menu.ant-menu-inline.ant-menu-sub.dropdown-menu {
    background: #ffffff;
}

.ant-menu-sub.ant-menu-inline {
    background: #ffffff !important;
}

.ant-menu-inline-collapsed li div span span {
    display: none;
}

.ant-menu-inline-collapsed li span span {
    display: none;
}

.ant-menu-inline-collapsed li i {
    margin-left: 8px;
}

.item-name {
    height: 18px !important;
    font-size: 12px !important;
    color: #2a2a2a !important;
    font-weight: 500 !important;
    font-family: 'Poppins' !important;
}

.item-detail-text {
    font-size: 12px !important;
    color: #979797 !important;
    font-family: 'Poppins' !important;
}

.inner-box h6 {
    font-family: 'Poppins';
    letter-spacing: 0.3px;
    font-size: 12px;
    min-height: 18px;
}

.inner-box p {
    font-family: 'Poppins';
    letter-spacing: 0.3px !important;
    font-size: 12px !important;
}

.ant-btn-primary {
    background-color: #255594 !important;
    border-color: #255594 !important;
}

.ant-btn-primary:hover {
    background-color: #3d7cce !important;
    border-color: #3d7cce !important;
}

.ant-menu-inline .ant-menu-item:after, .ant-menu-vertical .ant-menu-item:after, .ant-menu-vertical-left .ant-menu-item:after, .ant-menu-vertical-right .ant-menu-item:after {
    border-right: 3px solid #255b9a !important;
}
.ant-menu-inline .ant-menu-item:after{
    border-right: 3px solid #211f1f !important;
}

.ant-menu-item-selected {
    color: #fdfdfd !important;
    background: rgb(143 143 143 / 10%);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: rgb(30 87 154 / 8%) !important;
}

.ant-menu-submenu-selected {
    color: #22589a !important;
}

.ant-menu-item-active, .ant-menu-item:hover, .ant-menu-submenu-active, .ant-menu-submenu-title:hover, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #225699 !important;
}

.ant-menu-item:hover{
    color: white !important;
}

.filter-btn {
    position: relative !important;
    left: -51px !important;
    z-index: 99 !important;
    box-shadow: none !important;
    border: none !important;
    height: 36px !important;
    border-left: 1px solid #f0f4fa !important;
    margin-right: -45px !important;
}

.filter-btn:hover {
    background-color: #f8f8f8 !important;
}

.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
    font-size: 13px !important;
}

.reporting-only-uploads .ant-upload-list-item {
    display: none !important;
}

.vehicle-refill .ant-upload-list-item {
    display: none !important;
}

nz-option-item-group.ant-select-item.ant-select-item-group {
    font-size: 10px !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    font-weight: 600 !important;
    color: #bebebe !important;
}

@media print {
    body *:not(#print-section) {
        display: none;
    }
}

button.ant-btn-dangerous {
    padding: 0.65rem 3.5rem;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11px;
}

.ant-btn-sm {
    padding: 0.5px 13.5px !important;
    font-size: 11px;
}

.ant-btn-primary[disabled] {
    color: white !important;
    opacity: 0.6;
}

button.ant-upload-list-item-card-actions-btn {
    padding: 0px !important;
}

@media print {
    body * {
      visibility: hidden;
    }
    #section-to-print, #section-to-print * {
      visibility: visible;
    }
    #section-to-print {
      position: absolute;
      left: 0;
      top: 0;
    }
  }